import React, { useEffect, useState } from 'react'
import { navigate } from "gatsby"
import SEO from '../components/seo';
export default function PaymentFailure() {
    const [seconds,setSeconds]=useState(10);
    useEffect(()=>{
        const interval=setInterval(()=>{
            setSeconds(old=>{
                if(old<=0){
                    navigate('/');
                    clearInterval(interval)
                    return old;
                }
                return old-1
            })
        },1000)
    },[])
    return (
        <div className="vh-100 d-flex flex-column justify-content-center align-items-center">
            <SEO title="Payment Unsuccessful"/>
            <div className="display-4">Transaction Unsuccessful</div>
            <div className="">Redirecting to HomePage in {seconds} seconds...</div>
        </div>
    )
}
